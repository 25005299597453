import baseAxios from '@/api/base';

baseAxios.defaults.baseURL = `${process.env.VUE_APP_APIPATH}`;

/** Novices api */
/**
 * 取得新手指南資料
 * @param {object} data { noviceMode }
 */
export const GetNovicesByMode = (noviceMode) => baseAxios.post('/FrontEnd/Novices/NovicesId', { noviceMode });

/**
 * 取得聯絡我們
 * @param {object} data { }
 */
export const GetNovicesContact = () => baseAxios.post('/FrontEnd/NovicesContact/NovicesContactId');
