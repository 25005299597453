<template>
  <section>
    <ShoppingProgress :step.sync="step" />
    <section class="pb-8">
      <!-- 訂單明細 -->
      <OrderDetail :IsReady="IsReady" :step.sync="step" :ShoppingData.sync="ShoppingData" />
      <div class="container">
        <!-- 收件人資料 -->
        <div class="border-shadow p-3 p-md-6 mb-3 mb-md-8">
          <h4 class="mb-5">收件人資料</h4>
          <div class="row px-md-3">
            <div class="col-12">
              <div class="cs-table" data-style="7">
                <dl>
                  <dt>收件人姓名</dt>
                  <dd>
                    <p>{{ ShoppingData.orderName }}</p>
                  </dd>
                </dl>
                <dl>
                  <dt>收件人電話</dt>
                  <dd>
                    <p>{{ ShoppingData.orderPhone }}</p>
                  </dd>
                </dl>
                <dl>
                  <dt>收件人地址</dt>
                  <dd>
                    <p>
                      {{ ShoppingData.orderZipCode }} {{ ShoppingData.orderCounty }}
                      {{ ShoppingData.orderCity }}
                      {{ ShoppingData.orderAddress }}
                    </p>
                  </dd>
                </dl>
                <dl>
                  <dt>寄送注意事項</dt>
                  <dd>
                    <p>{{ ShoppingData.orderNote }}</p>
                  </dd>
                </dl>
              </div>
            </div>
          </div>
          <hr />
          <!-- 付款方式 -->
          <h4 class="mb-5">付款方式</h4>
          <p class="px-md-3">{{ ShoppingData.orderPayModeName }}</p>
          <div class="mb-5 text-danger" v-if="ShoppingData.orderPayMode === 3">
            ※需配合讀卡機操作<br />
            ※僅支援Windows作業系統
          </div>
          <!-- 發票資訊 -->
          <template v-if="ShoppingData.orderInvoiceType !== 0">
            <hr />
            <h4 class="mb-5">發票資訊</h4>
            <div class="row px-md-3">
              <div class="col-12">
                <div class="cs-table" data-style="7">
                  <dl>
                    <dt>發票</dt>
                    <dd>
                      <p>{{ ShoppingData.orderInvoiceTypeName }}</p>
                    </dd>
                  </dl>
                  <dl v-if="ShoppingData.orderInvoiceType === 2">
                    <dt>手機條碼</dt>
                    <dd>
                      <p>{{ ShoppingData.memberInvoiceCode }}</p>
                    </dd>
                  </dl>
                  <dl v-if="ShoppingData.orderInvoiceType === 3">
                    <dt>統一編號</dt>
                    <dd>
                      <p>{{ ShoppingData.memberInvoiceTaxId }}</p>
                    </dd>
                  </dl>
                  <dl v-if="ShoppingData.orderInvoiceType === 3">
                    <dt>抬頭</dt>
                    <dd>
                      <p>{{ ShoppingData.memberInvoiceTitle }}</p>
                    </dd>
                  </dl>
                  <dl v-if="ShoppingData.orderInvoiceType === 3">
                    <dt>電話</dt>
                    <dd>
                      <p>{{ ShoppingData.memberInvoicePhone }}</p>
                    </dd>
                  </dl>
                  <dl v-if="ShoppingData.orderInvoiceType === 3">
                    <dt>信箱</dt>
                    <dd>
                      <p>{{ ShoppingData.memberInvoiceEmail }}</p>
                    </dd>
                  </dl>
                  <dl v-if="ShoppingData.orderInvoiceType === 3">
                    <dt>地址</dt>
                    <dd>
                      <p>
                        {{ ShoppingData.memberInvoiceZipCode }}
                        {{ ShoppingData.memberInvoiceCounty }}
                        {{ ShoppingData.memberInvoiceCity }}
                        {{ ShoppingData.memberInvoiceAddress }}
                      </p>
                    </dd>
                  </dl>
                </div>
              </div>
            </div>
          </template>
        </div>

        <!-- 使用者合約 -->
        <div class="border-shadow p-3 p-md-6 mb-3 mb-md-8">
          <h4 class="mb-5">使用者合約</h4>
          <div class="row px-3 edit" v-html="ContractContent"></div>
          <div class="form-check mr-3">
            <input
              class="form-check-input"
              type="checkbox"
              id="agree"
              v-model="IsAgree"
              :true-value="1"
              :false-value="0"
            />
            <label class="form-check-label text-accent" for="agree">我已詳細閱讀並同意以上合約</label>
          </div>
        </div>
        <!-- 上一步/下一步按鈕 -->
        <div class="d-flex justify-content-between">
          <router-link
            v-if="orderId === 0"
            class="btn btn-outline-primary btn-hover-shadow px-6 py-2 py-md-3"
            to="/shopping/step2"
          >
            上一步
          </router-link>
          <router-link
            v-else
            class="btn btn-outline-primary btn-hover-shadow px-6 py-2 py-md-3"
            :to="{ name: 'Step2', params: { orderId } }"
          >
            上一步
          </router-link>
          <a
            href=""
            class="btn btn-primary btn-hover-shadow px-6 py-2 py-md-3"
            @click.prevent="next"
          >
            確認結帳
          </a>
        </div>
      </div>
      <div v-show="false" v-if="payReady" v-append="htmlCode"></div>
    </section>
  </section>
</template>

<script>
import ShoppingProgress from '@/components/ShoppingProgress.vue';
import OrderDetail from '@/components/Shopping/OrderDetail.vue';
import {
  GetShoppingCart,
  PayShoppingCart,
  GetOrderCart,
  PayOrderCart,
} from '@/api/ShoppingCartApi';
import { GetNovicesByMode } from '@/api/Novices';

export default {
  components: {
    ShoppingProgress,
    OrderDetail,
  },
  data() {
    return {
      orderId: 0,
      step: 2,
      ContractContent: '',
      IsAgree: 0,
      ShoppingData: {
        orderId: 0,
        shoppings: [
          {
            courseId: 0,
            courseName: '',
            courseOriPrice: 0,
            coursePrice: 0,
            classCount: 0,
            shoppingCartDetails: [
              {
                classId: 0,
                className: '',
                subjectName: '',
                teacherName: '',
              },
            ],
          },
        ],
        discountId: 0,
        discountMode: 0,
        discountName: '',
        discountCode: '',
        discountType: 0,
        discountPrice: 0,
        discountPercent: 0,
        discountMoney: 0,
        orderName: '',
        orderPhone: '',
        orderZipCode: '',
        orderAddress: '',
        orderNote: '',
        orderPayMode: 0,
        orderPayModeName: '',
      },
      IsReady: false,
      htmlCode: '',
      payReady: false,
    };
  },
  created() {
    const { orderId } = this.$route.params;
    if (orderId !== undefined) this.orderId = orderId;
    const { check } = this.$route.params;
    if (check === undefined) this.$router.replace('/');
    this.GetNovicesByMode();
    this.GetShoppingCart();
  },
  methods: {
    GetNovicesByMode() {
      const modelId = 6;
      GetNovicesByMode(modelId).then((response) => {
        if (response.data.statusCode === 0) {
          this.ContractContent = response.data.data.noviceContent;
        }
      });
    },
    GetShoppingCart() {
      let GetWay = GetShoppingCart;
      if (this.orderId !== 0) GetWay = GetOrderCart;
      GetWay(this.orderId).then((response) => {
        if (response.data.statusCode === 0) {
          this.ShoppingData = response.data.data;
          this.IsReady = true;
        }
      });
    },
    next() {
      if (this.IsAgree === 0) {
        this.$message({
          showClose: true,
          type: 'error',
          message: '請詳細閱讀使用者合約，閱讀完請勾選同意',
        });
        return;
      }

      this._.merge(this.ShoppingData, this.OrderForm);
      let PayWay = PayShoppingCart;
      if (this.orderId !== 0) PayWay = PayOrderCart;
      PayWay(this.orderId).then((response) => {
        this.$bus.$emit('getShoppingCartCount');
        if (response.data.statusCode === 0) {
          if (response.data.data.showType === 1) {
            this.$router.push({ name: 'Finish', params: { step: 4 } });
          } else {
            this.htmlCode = response.data.data.rtnHtml;
            this.$bus.$emit('setLoading');
          }
          this.payReady = true;
        } else {
          this.$router.push({ name: 'Finish', params: { step: 3 } });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.edit {
  height: 300px;
  overflow-y: auto;
}
</style>
